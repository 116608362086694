import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Catalyst } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityGridDetailsLayout, LinearNavigationProvider, useEntity } from '@shapeable/ui';
import { CatalystMainLayout } from './catalyst-main-layout';
import { CatalystAsideLayout } from './catalyst-aside-layout';
import { ExplorerProvider } from '../providers/explorer-provider';
import { useCatalysts } from '../../hooks/use-catalyst';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { classNames } from '@shapeable/utils';
import { SiteHeaderLayout, SiteHeaderProvider } from '@shapeable/web';
const cls = classNames('catalyst-layout');

// -------- Types -------->

export type CatalystLayoutProps = Classable & HasChildren & { 
  entity?: Catalyst;
};

export const CatalystLayoutDefaultProps: Omit<CatalystLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
    padding: ${theme.UNIT(5)} 0 0;
  `,
});

const AsideStyles = breakpoints({
  base: css`
    
  `,
});
const MainStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(EntityGridDetailsLayout)`${LayoutStyles}`,
      Aside: styled(CatalystAsideLayout)`${AsideStyles}`,
      Main: styled(CatalystMainLayout)`${MainStyles}`,

};

export const CatalystLayout: Shapeable.FC<CatalystLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const catalysts = useCatalysts();

  const { isShown } = useValueChainMap();
  
  return (
    <SiteHeaderProvider 
      value={{
        variant: 'light'
    }}>
    <ExplorerProvider>
    <LinearNavigationProvider value={catalysts}>
    <My.Container className={cls.name(className)}>
      <My.Header variant="light" />
      <My.Layout
        entity={entity}
        asideIsRevealed={isShown}
        aside={
          <My.Aside entity={entity} />
        }
        main={
          <My.Main entity={entity} />
        }
      />
      {children}
    </My.Container>
    </LinearNavigationProvider>
    </ExplorerProvider>
    </SiteHeaderProvider>
  )
};

CatalystLayout.defaultProps = CatalystLayoutDefaultProps;
CatalystLayout.cls = cls;